.request-files-modal {
  .card-block.fixed-panel {
    height: 300px;
    overflow: auto;
  }

  .contact-box {
    border-bottom: 1px solid #ced4da;
  }
}
