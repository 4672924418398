.roles-admin {
  .claim-section > div.center-buttons,
  .claim-section > div > select {
    height: 300px;
  }

  .chooser-list {
    border: solid 1px;
    overflow-y: auto;
  }
}