.list-supplier-view {
  .fa-check, .fa-ban {
    font-size: 18px;
    top: 2px;
    position: relative;
  }

  .checkbox {
    top: 3px;
  }

  .full button span {
    background-color: limegreen;
    border-radius: 32px;
    color: black;
  }

  .partially button span {
    background-color: orange;
    border-radius: 32px;
    color: black;
  }
}

.edit-supplier-view {
  .sky-form {
    border: none;
  }

  .progress {
    margin-bottom: 0;
  }

  .linked-icon {
    margin-left: 10px;
  }
}

.supplier-overview {
  .ui-select-no-choice {
    > li {
      > div {
        a {
          color: #2da5da;
        }
      }
    }
  }
}

.overlay-icon {
  position: relative;
  left: -7px;
  top: 4px;
}

.supplier-file-request .card-block.fixed-panel {
  height: 300px;
  overflow: auto;
}

.supplier-address {
  .ui-select-bootstrap {
    .dropdown-toggle {
      padding: .8rem 1rem .6rem;
    }
  }

  .ui-select-toggle {
    svg.fa-caret-down {
      top: 13px;
    }
  }
}
