/* ----- Top nav Dropdown Menu (Unify doesn't have a nice dropdown option - as of 2/2017) ----- */

.header {
  .topbar {
    ul.loginbar {
      li {
        .cf-top-dropdown {
          a {
            color: #7c8082;
            font-size: 11px;
            text-transform: uppercase;
          }
          a:after {
            display: none;
          }
        }
      }

      .has-items {
        a {
          color: #555555;
          font-weight: bold;
        }

        i.fa {
          color: #555555;
        }
      }
    }
  }

  .cf-top-dropdown {
    &.dropdown {
      &.open {
        padding-bottom: 5px;

        .dropdown-menu {
          display: block;
        }
      }
    }
  }

  .nav {
    .search-open {
      z-index: 999;
    }
  }
}

.u-header {
  .navbar-collapse.in {
    background-color: white;
  }
}
