.message-body {
  .message-body-container {
    overflow: auto;
    max-height: 33rem;
    display: flex;
    flex-direction: column-reverse;
  }

  .remove-message {
    position: absolute;
    right: 15px;
  }
}
