.marketplace {
  .marketplace-services {
    .content-boxes-v4-sm {
      .text-success {
        color: #72c02c;

        i {
          font-size: 16px;
          margin: 0;
        }
      }

      i {
        font-size: 26px;
        margin-top: 5px;
        margin-right: 5px;
      }

      p > a {
        font-size: inherit;
        font-weight: inherit;
      }

      h2 {
        margin-bottom: 0;
      }

      .subtitle {
        color: #777;
        font-weight: bold;
      }
    }
  }

  .services-offered {
    table {
      table-layout: fixed;

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        input[type="checkbox"] {
          line-height: normal;
          margin: 0;
        }
      }
    }
  }

  .edit-service-offered {
    .price {
      margin-bottom: 0;
    }
  }
}

.popover {
  .analyst-members {
    img {
      width: 14px;
      margin-right: 7px;
      margin-top: -2px;
    }
  }
}

.marketplace-clients {
  .popover-content {
    ol {
      margin-bottom: 5px;
    }
  }
}

.checkout {
  .radio {
    > span {
      line-height: 1.8;
    }

    div {
      line-height: 1;
    }
  }

  .new-card {
    padding-top: 0;
  }
}

.marketplace-expert-plan-review {
  .headline {
    margin-top: 0;
  }
}
