.onboarding-container {
  img.graphic {
    max-width: 100%;
    max-height: 100%;
  }

  .onboarding-survey {
    .form-group {
      margin-bottom: 0 !important;
    }

    .small {
      padding-left: 25px;
    }

    label {
      margin-bottom: 0;
    }
  }

  .body-container {
    min-height: 400px!important;
  }

  .country-select .form-control {
    padding: .8rem 1rem .6rem;
  }
}
