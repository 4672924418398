.products-edit-hazard {
  height: 100%;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    overflow: auto;
  }

  .h5 {
    line-height: 1;
    top: 1px;
  }
}
