.heading-banner {
  border-bottom: none;

  a {
    color: inherit;
    text-decoration: none;
  }

  h2 {
    border: none;
    line-height: .9;

    small {
      text-transform: uppercase;
      font-size: 50%;
    }

    > div {
      > div {
        display: inline-block;
      }

      > small {
        color: inherit;
        line-height: inherit;
        text-transform: none;
        font-size: 70%;
      }
    }
  }

  .help-banner-notification {
    margin-top: 10px;
  }
}
