.log-type-thermometer {
  .ta-editor.ta-html, .ta-scroll-window.form-control, .ta-scroll-window > .ta-bind {
    min-height: 100px;
  }

  .ta-editor {
    .placeholder-text::before {
      color: #999;
    }
  }
}
