.pdf-page-canvas {
  display: block;
  margin: 5px auto;
}

.pdf-page {
  position: relative;
  margin: 0 auto 3em auto;
  background-clip: content-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.54);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  overflow: auto;
}
