.card-image {
  height: 200px;
  background-color: #ccc;
  background-size: cover;
}

.card-image-primary {
  width: 600px;
  background-color: #ccc;
  background-size: cover;
}

.btn-primary:focus {
  background-color: #FF7800;
  border-color: #FF7800;
}
