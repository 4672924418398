.verification {
  .sky-form {
    border: none;

    fieldset {
      padding: 0;
    }
  }
}

.wizard-progress {
  width: 800px;
  list-style: none;
  list-style-image: none;
  padding: 0;
  height: 80px;
  position: relative;
}


.wizard-progress li {
  float: left;
  text-align: center;
  position: relative;
  height: 100%;
  margin-right: 6.9%;
  width: 14.4%;
}

.wizard-progress .step-name {
  position: absolute;
  vertical-align: bottom;
  text-align: left;
  top: 13px;
  padding-right: 5px;
  left: 30px;
  background-color: white;
}

.wizard-progress .step-num {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  border: 3px solid darkgray;
  background-color: darkgray;
  border-radius: 50%;
  width: 24px;
  left: 0;
  display: inline-block;
  margin-top: 10px;
  color: #fff;
}

.wizard-progress .step-num:after {
  content: "";
  display: block;
  background: darkgray;
  height: 1px;
  width: 125px;
  position: absolute;
  top: 11px;
  left: 30px;
}

.wizard-progress li:last-of-type {
  margin-right: 0;
}

.wizard-progress li:last-of-type .step-num:after {
  display: none;
}

.wizard-progress .step-done .step-num,
.wizard-progress .step-active .step-num {
  border-color: #000;
  background-color: #000;
}

.wizard-progress .step-active .step-name {
  font-weight: bold;
}

.steps-indicator {
  position: absolute;
  top: 70px;
}

.steps-indicator li {
  left: 15px;
}

.steps-indicator li.current a:before, .steps-indicator li.done a:before, .steps-indicator li.editing a:before {
  background-color: #2674f2!important;
}

.steps-indicator li.done a:before {
  content: '\2713';
  color: #ffffff;
  padding-right: 2px;
  font-weight: bold;
}

.steps-indicator li a {
  text-transform: none;
  font-weight: normal;

  position: absolute;
  top: -7px;
  left: calc(50% - 65px);
  background-color: white;
  padding-right: 5px;
  padding-left: 30px;
}

.steps-indicator li.current a, .steps-indicator li.editing a {
  font-weight: bold;
}

.steps-indicator li a:before {
  left: 10px;
  top: -1px;
  width: 17px;
  line-height: 17px;
  height: 17px;
}

.verification-bids {
  .order-success {
    text-align: center;

    span {
      display: block;
      font-size: 35px;
      font-weight: 200;
      margin-bottom: 15px;
    }

    p {
      color: #555;
      font-size: 16px;
    }
  }
}
