.batch-edit {
  .cf-barcode-input {
    margin-bottom: 0 !important;
  }
  .cf-checkbox-input{
    height: 20px;
    width: 20px;
  }
  .tbody-scroll{
    display: block;
    overflow: auto;
    max-height: 50vh;
  }

  #logs-table {
    width: 100%;
    table-layout: fixed;
  }

  //#lot-table thead > tr > th, #lot-table tbody > tr > td{
  //  width: 25%;
  //}

  .fifteen-percent {
    width: 15%;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  .form-control-lg {
    font-size: 1rem;
  }
}
