.products-edit-ingredients {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cards {
    flex: 1;
    overflow: auto;

    .media-body {
      .list-inline-item {
        line-height: initial;
        font-size: 12px;
      }

      .btn-outline-danger {
        color: #dc3545;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}
