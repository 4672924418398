.orders {
  tr {
    &.in {
      overflow-y: hidden;
    }
  }

  span {
    &.label {
      font-size: 11px;
      font-weight: 400;
      padding: 4px 7px;
    }
  }

  .progress {
    margin-bottom: 0;
  }

  table {
    table-layout: fixed;

    td {
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .form-control-feedback {
    z-index: 4;
  }
}
