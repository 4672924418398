.cf-textarea {
  .typeahead-results {
    width: 100%;
    background: white;
    z-index: 999;
    cursor: pointer;
    position: absolute;

    > div {
      border: 1px solid #ccc;
      padding: 10px;
      border-top: none;
    }

    &:hover {
      background: #ccc;
    }
  }

  .fa-spin {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .ng-invalid.ng-touched {
    background-color: #fff0f0;
    border-color: #f00;
  }
}
