.no-animate {
  -webkit-transition: none !important;
  transition: none !important;
}

.navbar-brand {
  img {
    height: 40px;
  }
}

.text-primary {
  //color: #72c02c!important;
  color: #FF7800!important
}

.click-able {
  cursor: pointer;
}

.u-btn-outline-blue.blue-v2 {
  color: #317ae2;
  border-color: #317ae2;

  &:hover {
    color: #fff;
    background-color: #317ae2;
  }
}

.btn-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
}
.btn-close{
  font-size: 25px;
  line-height: 0.6;
}
.link-disabled {
  &:active {
    pointer-events: none;
  }
  &:hover, &:focus {
    color: grey;
  }
  cursor: not-allowed;
  color: grey;
}

.growl-container.growl-fixed {
  min-width: 300px;
  width: inherit;

  .growl-message a {
    color: black;
    text-decoration: underline;
  }
}

select {
  &.form-control {
    &:not([size]):not([multiple]) {
      height: calc(2.25rem + 11px) !important;
    }

    &.form-control-md {
      &:not([size]):not([multiple]) {
        height: calc(2.25rem + 12px) !important;
      }
    }
  }

  &.form-control-sm {
    font-size: inherit;
  }
}

/* No idea why but prod/stage is getting a jacked height. Temp fix. */
.form-control {
  height: initial;
}

/* search-bar-icon is stand-alone. When a button group is used within the container search-bar, there's no
 good way to 'find' the input to place it. And an input can't have a pseudo element, so that's not an option. */
.search-bar, .search-bar-icon {
  position: relative;

  svg.fa-search {
    color: #999;
    position: absolute;
    top: 16px;
    left: 10px;
    z-index: 3;
  }
}

.search-bar-icon svg.fa-search{
  top: 14px;
}

.search-bar input,.search-bar-icon + input {
  padding-left: 27px;
}

.search-bar-icon:before {
  top: 9px;
}

.search-bar.searching:before, .search-bar-icon.searching:before {
  content: '\F021'!important;
  animation: fa-spin 2s infinite linear;
}

.dropdown.show-caret .dropdown-toggle:after {
  content: '\E900'!important;
}

.dropdown-menu {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.u-badge-v1--sm.not-attached {
  font-weight: bold;
  position: static;
  transform: none!important;

  &.g-bg-darkred {
    font-size: 14px;
  }
}

.popover-body {
  ol, p {
    margin-bottom: 0;
  }

  ol {
    padding-left: 20px;
  }
}

.webui-popover-title {
  font-size: 1.5rem;
  line-height: 1.5;
}

button.close {
  cursor: pointer;
}

.ta-toolbar {
  .btn-group {
    margin-bottom: 8px;
    margin-right: 4px;
  }
}

.u-icon-v1.u-icon-size--sm {
  svg {
    padding-top: .9rem;
  }
}

.u-check-icon-checkbox-v4 {
  svg {
    margin-left: 2px;
    margin-bottom: 1px;
  }
}

.csv-import {
  .results-container {
    max-height: 300px;
    overflow-y: auto;
  }

  .field-container {
    max-height: 300px;
    overflow-y: auto;
  }
}

.input-group-addon {
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  background-color: transparent;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  &:not(:last-child) {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.u-accordion__header {
  &.u-accordion-with-button {
    padding: 0.447rem 1.07143rem;

    .btn-col {
      margin-top: -1px;
    }
  }
}

.table-with-sub-table {
  margin-bottom: 0;

  > thead {
    border-right: 1px solid #32383e;
  }

  > tbody > tr {
    cursor: pointer;
  }

  .sub-table {
    border: 1px solid rgb(221, 221, 221);

    &:hover {
      background-color: inherit;
    }

    table {
      margin-bottom: 0;

      thead {
        border-right: 1px solid #32383e;
        border-left: 1px solid #32383e;
      }
    }

    tr {
      cursor: default;
    }

    td {
      table {
        border: 1px solid #ddd;
      }
    }

    .panel {
      margin-bottom: 0;
    }
  }

  .selected-row {
    border: 1px solid rgb(221, 221, 221);
  }
}

.form-inline-btn {
  padding-top: .7rem;
  padding-bottom: .7rem;
}

.top-menu-hr {
  border-color: gray;
  margin: 5px 0;
}

/* We sometimes gotta sneak a span in between .active and .nav-link. So this is a patch to the unify style. */
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .active > span > .nav-link {
  color: #ff7800;
}

.ui-select-container.ui-select-multiple .ui-select-match-item.btn-secondary {
  background-color: initial;
  border-color: initial;
}

.ui-select-multiple.ui-select-bootstrap4 .ui-select-match .btn-primary {
  color: initial;
}
/* ----- loading bar color ----- */

#loading-bar .bar {
  background: #72c02c;
}

#loading-bar .peg {
  -moz-box-shadow: #72c02c 1px 0 6px 1px;
  -ms-box-shadow: #72c02c 1px 0 6px 1px;
  -webkit-box-shadow: #72c02c 1px 0 6px 1px;
  box-shadow: #72c02c 1px 0 6px 1px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color:  #72c02c;
  border-left-color: #72c02c;
}

/* ----- accordion ----- */

.u-accordion {
  .panel-title {
    margin-bottom: 0;

    a {
      text-decoration: none;
    }
  }
}

/* ----- mini-tour ----- */
.webui-popover-content {
  padding: 9px 0 0 0!important;
}

.flex-fit-height {
  min-height: 0;
  flex: 1 1 auto;
  overflow-y: auto;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.footer-style{
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.6);
  border-top: 1px solid #dee2e6!important;
  padding: .5rem 2rem  .5rem 0;
  background-color: #fff!important;
  display: flex;
}
.footer-padding{
  padding-bottom: 4rem;
}

.bg-gray{
  background-color: #D3D3D3!important;
}

.multiselect-parent {
  button {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    width: 150px;
  }
  .dropdown-menu {
    .option{
      padding: 0;
    }
    a {
      padding-left: 15px;
    }
    label {
      padding-left: 15px;
      color: #0a0a0a;
    }
    .divider {
      height: 1px;
      margin: 5px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }
    .glyphicon .glyphicon-ok {
      color: #0a0a0a;
    }
    li > a > span {
      display: none;
    }
    .checkboxInput {
      vertical-align: middle !important;
    }
  }
}
ng-dropdown-multiselect > div > ul > li.divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  height: 1px;
  background-color: #e5e5e5;
}
ng-dropdown-multiselect > div > ul > li > a:hover {
  text-decoration: none;
}
ng-dropdown-multiselect > div > ul > li:hover {
  background-color: #F5F5F5;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FF7800!important; /* Change the color of the toggle when it is checked */
  border-color: #FF7800!important;; /* Change the border color of the toggle when it is checked */
}

