//Logs scss
.log-form-body {
  box-sizing: border-box;
  margin: 0px;
  width: 100%;
}

.download-modal {
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: 0 auto;
  padding: 10px;
  color: #555;
  font-size: 14px;
}

.image-container {
  display: flex;
  justify-content: center;
}

.log-image {
  height: 30rem;
}

.info-container {
  flex: 1;
  padding-left: 16px;
}

.download-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apple-button {
  width: 200px;
  padding-right: 16px;
}

.google-button {
  width: 200px;
}

.info-heading {
  color: #ff7800;
}

.main-heading{
  width: 100%;
  display: flex;
  justify-content: center;
}

.heading {
  color: #ff7800;
  text-align: center;
}

@media (max-width: 786px) {
  .download-modal {
    flex-direction: column;
  }
  .info-container {
    max-width: 420px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .download-buttons {
    flex-direction: column;
    text-align: center
  }
  .apple-button {
    width: 50%;
    padding-right: 0px;
    padding-bottom: 8px;
  }

  .google-button {
    width: 50%;
  }
}
