.products-edit {
  .main-container {
    display: flex;
    width: 100%;

    .product-box {
      flex: 1 1 auto;
      min-width: 300px;
    }

    .side-panel-container {
      flex: 0 1 30%;
    }

    @media only screen and (max-width: 480px) {
      .side-panel-container{
        flex: 1 1 30%;
      }
    }
  }

  .no-primary-color-on-focus:focus {
    color: white;
    background-color: #72c02c;
    border-color: #72c02c;
  }

  .side-panel {
    overflow: auto;
    display: flex;
    flex-direction: column;

    .li-bullet {
      transform: rotate(0deg);
      transition: transform .5s linear;
    }

    .li-bullet.open {
      transform: rotate(90deg);
      transition: transform .5s linear;
    }

    .side-panel-view {
      flex: 1;
      overflow: auto;
      border-bottom: solid 2px #72c02c !important;
    }

    [uib-dropdown] {
      align-self: flex-start;
    }

    .u-nav-v2-1 {
      .nav-item {
        &:hover {
          background-color: #fafafa !important;
        }
      }
    }
  }

    @media only screen and (max-width: 480px) {
      .fa-file-pdf, .fa-unlock{
        height:20px;
        width:20px;
      }
    }

  .main-panel {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-bottom: solid 2px #72c02c !important;

    .main-panel-view {
      flex: 1;
      overflow: auto;
    }

    .h5 {
      line-height: 1;
      top: 1px;
    }
  }

  .side-panel, .main-panel {
    height: 330px;

    @media screen and (min-height: 600px){
      height: 480px;
    }
    @media screen and (min-height: 740px){
      height: 555px;
    }
    @media screen and (min-height: 800px){
      height: 640px;
    }
    @media screen and (min-height: 1070px){
      height: 775px;
    }
    @media screen and (min-height: 1200px){
      height: 905px;
    }
  }

  .side-menu-tabs {
    .nav-link {
      padding-top: 9px;
      padding-bottom: 10px;
    }
  }

  .only-two-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 22.4px;     /* fallback */
    max-height: 44.8px;      /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .only-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 22.4px;     /* fallback */
    max-height: 44.8px;      /* fallback */
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .box-shadow-top {
    z-index: 1;
    position: relative;
    -moz-box-shadow: 0px -5px  5px -5px #ddd;
    -webkit-box-shadow: 0px -5px  5px -5px #ddd;
    box-shadow: 0px -5px 5px -5px #ddd;
  }
}
