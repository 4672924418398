.recall-team-view {
  .ta-editor.ta-html, .ta-scroll-window.form-control, .ta-scroll-window > .ta-bind {
    min-height: 150px;
  }

  .ta-editor {
    .placeholder-text::before {
      color: #999;
    }
  }

  th, td {
    word-wrap: break-word;
    max-width: 150px;
  }
}
