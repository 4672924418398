.cf-step-chooser {
  .u-btn-outline-lightgray {
    border-color: #ced4da;
  }

  .cf-select {
    .ng-invalid.ng-touched {
      background-color: #fff0f0;
      border-color: #f00;

      + .input-group-append {
        button {
          border-top-color: #f00;
          border-bottom-color: #f00;
        }

        button:last-child {
          border-right-color: #f00;
        }
      }
    }
  }
}
