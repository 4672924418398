.cf-draggable {
  padding-bottom: 2px;

  &.drag-boundary-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;

    .resizer {
      cursor: col-resize;
      width: 4px;
      background: #eee;
      z-index: 99;
    }

    div {
      flex-grow: 1;
      height: 100%;
    }

    .blocker {
      flex: 0 0 300px;
    }
  }

}
