.products-edit-info {
  height: 100%;
  display: flex;
  flex-direction: column;

  .info {
    flex: 1;
    overflow: auto;
  }

  .row {
    .col {
      min-width: 300px;
    }
  }
}
