.order-payment {
  .tag-box h2, .alert h2 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
  }

  table {
    .checkbox {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .sky-form {
    border: none;

    section {
      margin-bottom: 0;
      padding-bottom: 10px;
    }
  }

  /* Since we aren't using skyforms, we gotta manually set some styling options for this email checkbox */
  .receipt-email {
    position: relative;
    left: 20px;

    .checkbox {
      cursor: pointer;
    }

    span {
      font-weight: normal;
    }
  }

  .row.save-payment-method {
    padding-top: 10px;

    .checkbox {
      display: inline-block;
      padding-right: 10px;
    }

    .input {
      display: inline-block;

      input {
        width: 222px;
      }
    }

    .input.visibility-hidden {
      input {
        visibility: hidden;
      }
    }
  }

  .row.promo-code {
    input {
      width: 222px;
    }
  }

  .send-receipt {
    margin-top: 15px;
  }
}

.pricing-card {

  .pricing-panel-container{
    display:flex;
    width:100%;
    padding: 10px;
  }

  .pricing-panel {
    position: relative;
    flex: 1;
    border: 2px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 2px;
    margin-right: 16px;
    border-radius: 8px;
    cursor: pointer;
  }

  .pricing-panel-selected {
    box-shadow: 0 10px 6px -6px #ddd;
    border: 2px solid #dc7442;
    background-color: #fef5ee;
  }

  .pricing-panel:last-child {
    margin-right: 0px;
  }

  .pricing-panel-title {
    color: #888;
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .pricing-panel-cost {
    font-size: 20px;
    font-weight: bold;
  }

  .pricing-panel-description {
    color: #888;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 4px;
    font-weight: bold;
  }

  .pricing-panel-message {
    color: #888;
    font-size: 12px;
    font-style: italic;
  }

  .pricing-panel-save {
    position: absolute;
    top: -24px;
    right: -24px;
    background: green;
    color: white;
    padding: 16px;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}
