.onboarding-first-plan{
  .panel-container{
    display:flex;
    width:100%;
  }

  .template-panel {
    position: relative;
    flex: 1;
    border: 2px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 2px;
    margin-right: 16px;
    border-radius: 8px;
    cursor: pointer;
  }

  .panel-selected {
    box-shadow: 0 10px 6px -6px #ddd;
    border: 2px solid #dc7442;
    background-color: #fef5ee;
  }

  .template-panel:last-child {
    margin-right: 0px;
  }

  .panel-text {
    font-size: 20px;
    font-weight: bold;
  }
}
