$cf-color: #72c02c;

@media (min-width: 992px) {
  .nav-users {
    position: fixed;
  }

  .plan {
    width: 920px;
  }

  .sign-up {
    width: 580px
  }

  .supplier-option {
    width: 800px
  }
}

/* Portrait IPHone 5 and 5S*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .sign-up {
    width: 270px;
  }
}

.remove-account-container {
  display: inline-block;
  position: relative;
}

.btn-remove-account {
  top: 10px;
  right: 10px;
  position: absolute;
}

.invoice-list a:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.invoice-list .panel-group {
  margin-bottom: 0;
}

.billing .sky-form section {
  margin-bottom: 10px;
}

.billing .sky-form hr {
  margin-top: 0;
  margin-bottom: 15px;
}

.billing .panel-body {
  overflow: hidden;
}

.billing .read-mode, .billing .edit-mode {
  position: relative;
}

/* ----- Animations ----- */

.billing .read-mode.ng-hide-remove {
  animation: bounceInLeft 1s;
  -webkit-animation: bounceInLeft 1s;

}

.billing .read-mode.ng-hide-add {
  animation: bounceOutLeft 1s;
  -webkit-animation: bounceOutLeft 1s;
}

.billing .edit-mode.ng-hide-remove {
  animation: bounceInRight 1s;
  -webkit-animation: bounceInRight 1s;

}

.billing .edit-mode.ng-hide-add {
  animation: bounceOutRight 1s;
  -webkit-animation: bounceOutRight 1s;
}

.billing .edit-mode.ng-hide-remove, .billing .edit-mode.ng-hide-add {
  top: -107px;
}

.billing .form-alert.ng-hide-remove {
  animation: bounceIn .5s;
  -webkit-animation: bounceIn .5s;

}

.billing .form-alert.ng-hide-add {
  animation: bounceOut .5s;
  -webkit-animation: bounceOut .5s;
}

.or-line {
  position: relative;
  border-color: #7b8087;
}

.or-line:after {
  position: absolute;
  content: 'or';
  left: 50%;
  margin-left: -15px;
  top: -10px;
  background-color: #fff;
  padding: 0 10px;
  text-align: center;
  color: #7b8087;
}

#notifications .two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.pricing-table-v8 .service-block h2 {
  color: #fff;
}

.pricing-table-v8 .service-block p {
  color: #fff;
}

.supplier-option .choose-option {
  padding-top: 30px;
  padding-bottom: 100px;
}

.distributor-product-request-view .info {
  margin-left: 5px;
}

.notifications-panel {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.billing {
  .headline {
    &:first-child {
      margin-top: 0;
    }
  }

  .state {
    padding: 6px 8px;
  }

  .no-invoices-container {
    min-height: 107px;
    padding: 42px;
  }

  .invoices-container {
    padding-top: 8px;
  }

  .padding-small {
    padding-left: 5px;
  }
}

.new-pw-view {
  .sky-form {
    .progress {
      float: none;
    }
  }
}

.captcha-container {
  margin: 0 auto;
  min-height: 78px;
}

.choose-plan {
  .sky-form {
    margin-bottom: 5px;

    .radio {
      color: #777;
    }
  }

  .reg-block {
    padding-top: 10px;
  }

}

.notification-ellipsis {
  left: 7px;
  a {
    color: rgb(88, 95, 105);
  }
}

.trial-badge {
  color: $cf-color;
  border: solid 1px;
  padding: 3px;
  margin-left: 5px;
}

@media screen and (max-width: 500px){
  #rc-imageselect, .g-recaptcha, .captcha-container {
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }
}
