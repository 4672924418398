.cf-yes-no {
  .fixed-yes-no {
    @media (max-width: 991px) {
      width: 75px;
    }
    @media (min-width: 992px) {
      width: 150px;
    }
  }

  .additional-option.fixed-yes-no {
    @media (max-width: 991px) {
      width: 100px;
    }
    @media (min-width: 992px) {
      width: 230px;
    }
  }
}
