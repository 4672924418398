.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}

.show > .dropdown-menu {
  display: block;
}

.progress {
  height: 1rem;
}

.dropdown-menu {
  padding: 0.5rem 0;
}

.dropdown .dropdown-toggle {
  &:after {
    content: none;
  }
}

/* Have dropdown menu drop to the left instead of to the right (and off of the table view).*/
.table .dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-item a {
  color: initial;
}

/* In bootstrap 4, 'in' was replaced with 'show'. angular ui bootstrap still uses 'in' though. */
.collapse.in {
  display: block;
}

.alert.alert-dismissible {
  padding-right: 30px;
}
