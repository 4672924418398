.edit-product-view {
  .headline {
    margin-top: 0;

    .breadcrumb {
      top: -1px;
    }

    h3 {
      small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        display: inline-block;
        line-height: 1.7;
      }
    }
  }

  fieldset {
    padding: 0;
  }
}

.ccp-color {
  color: #721c24;
}

.get-expert-button {
  font-size: 15px;
  padding: 8px 16px;
  font-weight: bold;
}
.get-expert-button:focus {
  color: white;
}

.plan-type-nav {
  margin: 0;
  height: 37px;
  width: 100%;
  background-color: #fff;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
  margin-top: 16px;
}

.product-title {
  padding-left: 0px;
}

.products-list-title {
  padding-left: 18px;
}

.col-request-review {
  padding-top: 5px;
}

.request-review {
  color: #fff !important;
  border: solid 2px #72c02c !important;
}

.request-review:hover {
  color: #72c02c !important;
  border: solid 2px #72c02c !important;
}

.plan-type {
  color: #72c02c;
  font-weight: bold;
}

.plan-type-title {
  text-align: center;
}

h4.plan-type {
  margin-bottom: 5px;
}

.plan-type-button {
  background-color: #676B65;
}

.plan-type-button:hover {
  background-color: #939692;
}

.plan-type-button:focus {
  background-color: #939692;
}

.plan-type-report-group {
  height: 32px !important;
}

.plan-type-report-button {
  height: 32px !important;
  background-color: #676B65;
}

.plan-type-report-button:hover {
  background-color: #72c02c;
}

.plan-type-report-group label.active {
  background-color: #fff;
  color: #72c02c;
}

.plan-type-report-group label.active:hover {
  background-color: #72c02c;
}

.plan-type-nav .open .dropdown-toggle.btn-u {
  background: #72c02c;
}

.plan-type-button-title {
  background-color: #fff;
  color: #72c02c;
  font-size: 18px;
  border: 1px solid #fff;
  padding-top: 3px;
  padding-bottom: 3px;
}

.plan-type-button-title:hover {
  background-color: #F1F1F1;
  color: #72c02c;
  border: 1px solid lightgrey;
}

.plan-type-button-title:focus, .plan-type-button-title:active, .plan-type-button-title:active:focus {
  background: #F1F1F1;
  color: #72c02c;
  border: 1px solid lightgrey;
}

.plan-type-nav .open .dropdown-toggle.btn-primary {
  background: #F1F1F1;
  color: #72c02c;
  border: 1px solid lightgrey;
}

.plan-type-nav .open .dropdown-toggle.btn-primary:focus, .plan-type-nav .open .dropdown-toggle.btn-primary:active {
  background: #F1F1F1;
  color: #72c02c;
  border: 1px solid lightgrey;
}

.file-name-label {
  position: relative;
  top: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 59%;
}

#verification .btn-u:hover {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.edit-product-view {
  h2 {
    margin-top: 0;
  }

  .sky-form {
    border: none;
  }
}

.add-procedure a:hover {
  cursor: pointer!important;
}

.control-help-box {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.control-help-box .help-link {
  margin-right: 13px;
}

#productList .dropdown-menu {
  right: 0;
  left: auto;
}

.add-product-view {
  .choose-product-type {
    .sky-form {
      section {
        margin-bottom: 5px;

        &:first-child {
          margin-bottom: 0;
        }
      }

      hr {
        margin: 15px 0;
      }

      .variant {
        margin: 0 25px 15px 25px;
      }
    }
  }

  .fa-question-circle {
    cursor: pointer;
  }
}

.add-product-view .sky-form {
  border: none;

  .add-action-buttons {
    .fa-cogs {
      color: #72c02c;
    }
  }
}

.add-product-view fieldset {
  border: none;
  padding: 0;
}

.copy-product-modal {
  form {
    padding-top: 10px;
  }

  ul {
    padding-left: 20px;
  }
}

.ui-select-choices-group {
  div.active {
    .text-muted {
      color: #fff;
    }
  }
}

.create-product-group {
  .plan-select {
    border: 1px solid #ddd;
    padding-top: 15px;
    background:#fff;
    margin-left:25px;

    .plan-select-button {
      background:#fff;
      border: none;
      padding-top: 20px;
      padding-bottom: 19px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-bottom: 0px;
      }

      .top {
        position:absolute;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          left:29.8%;
        }
      }
    }

  }

  .plan-select-button img {
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }

  .plan-select img.top:hover {
    opacity:0;
  }

  .upload-only {
    border: 1px solid #ddd;
    padding-top: 18px;
    background:#fff;
    .upload-only-button {
      background:#fff;
      border: none;
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }
}
