.dashboard {
  [class*="u-nav-v5"] .nav-link {
    border-bottom: 1px solid transparent;
  }

  [class*="u-nav-v5"].u-nav-primary .nav-link.active {
    border-color: #72c02c;
    color: #72c02c;
  }
}
