.register {
  .captcha-container {
    margin: 0 auto;
    display: table;
    width: auto;
  }
}

.plan-description {
  color: #fff;
}

.signup-gradient-light {
  background-image: linear-gradient(to bottom,rgba(128,128,128,.4),transparent);
}

.g-bgi-v2 {
  background-image: url('../../_img/bg_signup.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.signin-container {
  background-image: url('../../_img/bg_signup.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  width: 100%;
}

.login-box {
  max-width: 530px;
  margin-left: 10%;
}

.doc-img {
  width: 130%;
  max-width: 350px;
}

.google-icon {
  cursor: pointer;
  background-size: 14rem;
  height: 3.5rem;
  width: 14rem;
  background-image: url("../../_img/btn_google_signin_dark_normal.png");
}
