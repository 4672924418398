.cf-timespan-picker {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .units-selection {
    .form-group {
      margin-bottom: 0 !important;
    }
  }

  .ng-invalid.ng-touched {
    background-color: #fff0f0;
  }

  .form-check-inline {
    top: -4px;
  }
}
