.co-overview {
  .logo, figure {
    height: 149px;
  }

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
    max-height: 100%;
  }
}
