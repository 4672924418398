/* ------------------------------------------- */
/* Animate supplier-ingredient
/* ------------------------------------------- */
$fade-in-transition: 400ms ease-out all;

.animate-fade-in.ng-hide-add,
.animate-fade-in.ng-hide-remove.ng-hide-remove-active {
  transition: $fade-in-transition;
  transform: scale(1);
  display: table-row !important;
  opacity: 1;
}

.animate-fade-in.ng-hide-remove,
.animate-fade-in.ng-hide-add.ng-hide-add-active {
  transition: $fade-in-transition;
  transform: scale(0.7);
  opacity: 0;
  display: table-row !important;
}

.ingredient-files {
  min-height: 30px;
}
