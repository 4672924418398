.feature-card ul {
  list-style-type: none;
}
.feature-card ul li{
  padding: 10px;
}

.btn-1 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  border-radius: 3rem !important;
  font-weight: bold !important;
  color: black;
}

.pill {
  background-color: rgba(255, 120, 0, 0.2);
  color: rgb(255, 120, 0);
  border-radius: 3rem;
  line-height: 1 !important;
}

.price-card{
  background-color: #f8f9fa !important;
}
.price-card .second-text {
  margin-left: 2.4rem;
  color: #888;
  font-size: 12px;
}
label i {
  color: #ff7800;
}
.active-card{
  background:  #ff7800!important;
  color: #fff;
  border-radius: 20px !important;
  font-size: 12px;
}
.active-card .fa-check-circle{
  background:  #fff;
  color: #ff7800;
  border-radius: 50%;
  padding: 0;
}

.active-card .month-text {
  color: #fff;
}
.active-card .second-text{
  margin-left: 2.4rem;
  color: #fff;
}
.month-text {
  color: #888;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}
.fa-times-circle{
  float : right;
  color: gray;
}
.feature-card .fa-check-circle{
  float : right;
  background: #fff;
  color: #ff7800;
  border-radius: 50%;
  border-color:  #ff7800;
}

.card {
  border-radius: 20px !important;
}

.offer-text {
  font-size: 9px;
}
