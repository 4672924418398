.products-edit-diagram {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  #myDiagramDiv {
    div {
      overflow: hidden!important;
    }
  }

  main {
    flex: 1;
    overflow: hidden;
    box-shadow: inset 0 0 5px #ddd;
  }

  canvas {
    outline: none;
  }

  .ingredient-list {
    padding-left: 15px;
  }

  .tag-box {
    margin-bottom: 0;
  }

  .quick-list {
    z-index: 10;
    position: absolute;
    max-width: 250px;
    visibility: hidden;
    transition: transform .3s;
    -webkit-transition: transform .3s;
    transform: scale(0.1);
    -webkit-transform: scale(0.1);

    &.open {
      visibility: visible;
      transform: scale(1);
      -webkit-transform: scale(1);
      transform: translateX(70px);
      -webkit-transform: translateX(70px);
    }

    .list-group-item {
      border-color: #C8C8C8;
      background-color: #F0F0F0;
    }

    .item:hover {
      background-color: #e6e6e6;
    }

    .btns-container {
      white-space: nowrap;
    }

    .controls-quick-list {
      li:first-child {
        background-color: #73dd73;
      }
    }

    .hazards-quick-list {
      li:first-child {
        background-color: #ffc999;
      }
    }
  }

  .quick-controls {
    z-index: 10;
    position: absolute;

    .btn-u.btn-brd {
      margin-left: -10px;
      margin-top: 5px;
      background-color: #fff;
      -webkit-box-shadow: 0 4px 4px -2px #bbb;
      box-shadow: 0 4px 4px -2px #bbb;

      &.btn-u {
        &.btn-brd {
          padding: 2px 5px;

          &:hover {
            background-color: #fff;
          }
        }
        &.btn-brd.small {
          padding: 0 3px;
          font-size: 12px;
        }
        &.btn-brd.large {
          padding: 3px 7px;
          font-size: 16px;
        }
      }
    }
  }

  .palette {
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 100;
    opacity: .9;

    &:hover {
      opacity: 1;
    }

    .tag-box {
      width: 150px;

      h5 {
        margin-bottom: 0;
      }

      p {
        color: #555;
        font-size: 10px;
      }

      .row:first-child {
        margin-bottom: 15px;
      }
    }

    .controls {
      padding: 10px 10px;
      background-color: #fff;
      border: solid 1px #72c02c;
      border-radius: 6px;

      .diagram-node {
        cursor: move;
        padding: 5px;
        border-radius: 6px;
        border-style: solid;
        border-width: 1px;
        border-color: #ddd;
        background-color: #f9f9f9;
      }
    }
  }
}

.floating-tip {
  position: absolute;

  +.popover > .arrow {
    display: none;
  }
}

.tip-overlay.active {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0,0,0,0.5); /*dim the background*/
}

.tip-dialog {
  padding: 10px 10px;
  max-width: 300px;

  p {
    margin-bottom: 10px;
  }

  button {
    margin: 10px auto;
  }
}

.radial-menu {
  .menu-item {
    opacity: 0;
    position: absolute;
    transform: translate(0px, 0px);
    transition: .2s;
    white-space: nowrap;

    button {
      position: relative;
      top: -5px;
      left: 0;
      font-size: 10px;
    }
  }

  &.open {
    >.menu-item {
      visibility: visible;
      opacity: 1;

      > .btn:not(:hover) {
        background-color: white;
      }
    }

    >.menu-item:nth-child(1) {
      transform: translate(-35px, -45px);
      transition-delay: 0s;
    }

    >.menu-item:nth-child(2) {
      transform: translate(-55px, 0);
      transition-delay: 0.1s;
    }

    >.menu-item:nth-child(3) {
      transform: translate(-35px, 45px);
      transition-delay: 0.2s;
    }

    >.open-right.menu-item:nth-child(1) {
      transform: translate(-25px, -45px);
    }

    >.open-right.menu-item:nth-child(2) {
      transform: translate(15px, 0);
    }

    >.open-right.menu-item:nth-child(3) {
      transform: translate(-25px, 45px);
    }
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
    transform: translate(0px, 0px);
    transition: visibility 0s linear 300ms, opacity 300ms;
  }
}

.diagram-toolbar {
  padding-top: 0!important;
  height: 41.5781px;
  .btn {
    border-radius: 0!important;
    border: none;
    flex: 0 0 35px;
  }
}
