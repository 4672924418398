
#files-table {
  width: 100%;
  table-layout: fixed;
}

//#lot-table thead > tr > th, #lot-table tbody > tr > td{
//  width: 25%;
//}

.fifteen-percent {
  width: 15%;
}

.ten-percent {
  width: 10%;
}
