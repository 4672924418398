.cf-input-with-unit {
  .bg-readonly {
    background-color: #e9ecef;
  }
  .form-control-sm{
    font-size: inherit;
  }
  .ng-invalid.ng-touched {
    background-color: #fff0f0;
    border-color: #f00;
  }
}
