@keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDownSmall {
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
}

.fadeInDownSmall {
  animation-name: fadeInDownSmall;
}

.fade-in-up.ng-enter {
  -webkit-animation: fadeInUpSmall 0.8s;
  -moz-animation: fadeInUpSmall 0.8s;
  -ms-animation: fadeInUpSmall 0.8s;
  animation: fadeInUpSmall 0.8s;
}

.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

.fade-out.ng-leave {
  -webkit-animation: fadeOut 0.2s;
  -moz-animation: fadeOut 0.2s;
  -ms-animation: fadeOut 0.2s;
  animation: fadeOut 0.2s;
}

$slide-in-duration: 0.5s;

.slide-in-right.ng-enter, .slide-in-right.ng-hide-remove {
  -webkit-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  -moz-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  -ms-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
}

.slide-in-left.ng-enter, .slide-in-left.ng-hide-remove {
  -webkit-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  -moz-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  -ms-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
}

.slide-out-left.ng-leave, .slide-out-left.ng-hide {
  -webkit-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  -moz-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  -ms-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
}

.slide-out-right.ng-leave, .slide-out-right.ng-hide {
  -webkit-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  -moz-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  -ms-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
}

// HACK: for use with ui-router (when the ui-view is always a direct descendant)

.slide-in-right > .ng-enter {
  -webkit-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  -moz-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  -ms-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
}

.slide-in-left > .ng-enter {
  -webkit-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  -moz-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  -ms-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
}

.slide-out-left > .ng-leave {
  -webkit-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  -moz-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  -ms-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
}

.slide-out-right > .ng-leave {
  -webkit-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  -moz-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  -ms-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
}

// HACK: for use with ui-router (when the ui-view can be multiple levels deep)

.slide-in-right .animated-ui-view.ng-enter {
  -webkit-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  -moz-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  -ms-animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
  animation: slideInRight $slide-in-duration, fadeIn $slide-in-duration;
}

.slide-in-left .animated-ui-view.ng-enter {
  -webkit-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  -moz-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  -ms-animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
  animation: slideInLeft $slide-in-duration, fadeIn $slide-in-duration;
}

.slide-out-left .animated-ui-view.ng-leave {
  -webkit-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  -moz-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  -ms-animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
  animation: slideOutLeft $slide-in-duration, fadeOut $slide-in-duration;
}

.slide-out-right .animated-ui-view.ng-leave {
  -webkit-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  -moz-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  -ms-animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
  animation: slideOutRight $slide-in-duration, fadeOut $slide-in-duration;
}
