.products-edit-control {
  height: 100%;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    overflow: auto;
  }

  .h5 {
    line-height: 1;
    top: 1px;
  }
}

.remove-btn {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
}
