.plan-analysis-edit-media {
  .sky-form .progress {
    float: none;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.plan-thumbnail {
  width: 200px;
  border: solid thin lightgrey;
}
