.circular-menu {
  margin: 0 auto;
  border: solid transparent;  // This is a hack to make IE consistent with Chrome and others.
}

.circle {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);

  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.open.circle {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.circle {
  > div {
    > * {
      display: block;
      position: absolute;
    }
  }
}
