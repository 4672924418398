.edit-product-procedure {
  .ta-editor.ta-html, .ta-scroll-window.form-control {
    min-height: 100px;
    max-height: 100px;
  }

  .ta-scroll-window > .ta-bind {
    min-height: 100px;
    max-height: 100px;
  }

  .ta-editor {
    .placeholder-text::before {
      color: #999;
    }
  }
}
